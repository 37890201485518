













































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import { HollowDotsSpinner } from 'epic-spinners';
import { mapGetters } from 'vuex';
import ThemeModel from '@/dtos/theme';

export default Vue.extend({
  components: { HollowDotsSpinner },
  data() {
    return {
      now: new Date(),
    };
  },
  created() {
    // Update current time every 10s
    setInterval(() => {
      this.now = new Date();
    }, 10000);
  },
  computed: {
    ...mapGetters({
      activeSession: 'viewer/getActiveSession',
      sessionTheme: 'viewer/getSessionTheme',
    }),
    theme(): ThemeModel {
      return this.sessionTheme as ThemeModel;
    },
    hasSessionStarted() {
      if (this.activeSession) {
        if (this.now > this.activeSession.startAt.toDate()) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },
  },
});
